.page__container {
  flex: 1 0 auto;
}

.page__content {
  height: 100%;
}

.main__container {
  box-sizing: border-box;
  min-width: 320px;
  padding: 0 16px;
  margin-top: 111px;
}

@media (min-width: 768px) {
  .main__container {
    width: 720px;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
  }
}

@media (min-width: 1200px) {
  .main__container {
    width: 1144px;
  }
}

@media (min-width: 1920px) {
  .main__container {
    width: 1530px;
  }
}

.main__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main__logo {
  width: 125px;
  height: 108px;
  margin-bottom: 13px;
}

.main__logo img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.input {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 193px;
  margin-bottom: 16px;
}

.input {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 193px;
  margin-bottom: 16px;
}

.input__input {
  font-size: 16px;
  line-height: 36px;
  height: 36px;
  padding: 0 8px;
  resize: none;
  outline: none;
  color: #000;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #f5f9fe;
  border: 1px solid #28afb0;
  box-sizing: border-box;
  border-radius: 8px;
}

.input__input::-moz-placeholder {
  color: #1f271b;
  opacity: 0.2;
}

.input__input:-ms-input-placeholder {
  color: #1f271b;
  opacity: 0.2;
}

.input__input::placeholder {
  color: #1f271b;
  opacity: 0.2;
}

.input__input::-ms-clear {
  display: none;
}

.input__input_error {
  border-color: #f00;
}

.input_error-text {
  display: none;
}

.input_error .input__input {
  border-color: #f00;
}

.input_error .input__label {
  display: flex;
}

.main__button {
  background: #2baa99;
  box-shadow: -1px 3px 10px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  width: 193px;
  height: 41px;
  font-size: 16px;
  line-height: 41px;
  color: #fff;
  border: 0;
  font-weight: 500;
}

.main__logo-text {
  width: 134px;
  height: 25px;
  margin-bottom: 6px;
  text-align: center;
}

.popup-page__content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2000;
  text-align: center;
}

.popup-page__content a {
  color: #19647e;
  margin-left: 5px;
  text-decoration: none;
}

.profile__data {
  font-size: 16px;
  color: #19647e;
  margin-left: 5px;
  text-decoration: none;
}

.market__label {
  color: #000;
  margin-left: 5px;
  text-decoration: none;
}

.profile-page__body {
  position: relative;
  background: rgba(246, 250, 251, 0.815);
  border-radius: 16px;
  padding: 44px 4px;
  width: 240px;
  box-sizing: border-box;
  align-items: center;
  text-align: center;
}

.profile__img {
  position: absolute;
  top: -65px;
  left: 70px;
}

.profile__img img {
  box-shadow: -1px 3px 10px rgba(0, 0, 0, 0.06);
  border-radius: 50px;
}

.corner__name {
  font-size: 24px;
  line-height: 34px;
  color: #000000;
  margin-bottom: 8px;
  width: 90%;
  margin: 4px auto;
}

.popup-page__logout {
  cursor: pointer;
  background-color: transparent;
  border: none;
  margin: 10px auto;
}

.overlay {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  transition: 0.3s;
}

.overlay_active {
  opacity: 1;
  visibility: visible;
}

.overlay-success {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: linear-gradient(270.17deg, #006a97 -4.85%, #d4ff9d 118.36%),
    linear-gradient(180.09deg, #006a97 9.65%, #d4ff9d 125.55%);
  z-index: 1000;
  transition: 0.3s;
}

.new_order {
  display: flex;
  flex-direction: column;
  background-color: rgba(10, 184, 155, 0.3);
  z-index: 1001;
  text-align: center;
  position: absolute;
  top: 20%;
  right: 30px;
  left: 30px;
  border-radius: 12px;
  box-shadow: -1px 3px 10px rgba(0, 0, 0, 0.06);
}

.new_order_num {
  font-size: 80px;
  font-weight: 600;
  color: #fff;
}

.new_order_text {
  padding: 10 auto;
  font-size: 16px;
  font-weight: 300;
  color: #fff;
  margin-top: 14px;
}

.ocean {
  height: 5%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #015871;
}

.wave {
  background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/85486/wave.svg) repeat-x;
  position: absolute;
  top: -198px;
  width: 6400px;
  height: 198px;
  animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
}

.wave:nth-of-type(2) {
  top: -175px;
  animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite, swell 7s ease -1.25s infinite;
  opacity: 1;
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}

@keyframes swell {
  0%,
  100% {
    transform: translate3d(0, -25px, 0);
  }
  50% {
    transform: translate3d(0, 5px, 0);
  }
}
