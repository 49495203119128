@font-face {
  font-family: "Futura New";
  src: url("FuturaNewLight-Reg.c3e4e517.eot");
  src: local("Futura New Light Reg"), local("FuturaNewLight-Reg"), url("FuturaNewLight-Reg.c3e4e517.eot#iefix") format("embedded-opentype"), url("FuturaNewLight-Reg.9d1d45c1.woff2") format("woff2"), url("FuturaNewLight-Reg.edbe4070.woff") format("woff"), url("FuturaNewLight-Reg.9526e345.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Futura New";
  src: url("FuturaNewBold-Reg.804ccc84.eot");
  src: local("Futura New Bold Reg"), local("FuturaNewBold-Reg"), url("FuturaNewBold-Reg.804ccc84.eot#iefix") format("embedded-opentype"), url("FuturaNewBold-Reg.7b608f5d.woff2") format("woff2"), url("FuturaNewBold-Reg.cd994ed8.woff") format("woff"), url("FuturaNewBold-Reg.ce513915.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Futura New";
  src: url("FuturaNewBook-Reg.08ed4795.eot");
  src: local("Futura New Book Reg"), local("FuturaNewBook-Reg"), url("FuturaNewBook-Reg.08ed4795.eot#iefix") format("embedded-opentype"), url("FuturaNewBook-Reg.24b9e46b.woff2") format("woff2"), url("FuturaNewBook-Reg.b7171b3b.woff") format("woff"), url("FuturaNewBook-Reg.f5a339aa.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
body {
  font-family: "Futura New";
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  color: #000;
  background-color: #f5f9fe;
}
.container {
  width: 96%;
  margin: 0 auto;
  padding: 0 6px;
}
.main {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr min-content;
  grid-template-areas: "head" "orders" "complete";
  min-height: 100vh;
}
.header {
  grid-area: head;
}
.orders {
  grid-area: orders;
}
.complete-orders {
  grid-area: complete;
}
.ninja-logo {
  opacity: 0.6;
}
.header {
  position: fixed;
  width: 100%;
  top: 0;
  background-color: #ffffff;
  z-index: 20;
  border-bottom: 1px solid #60d6a9;
  -webkit-box-shadow: 0px 5px 10px 2px rgba(174, 232, 191, 0.2);
  -moz-box-shadow: 0px 5px 10px 2px rgba(174, 232, 191, 0.2);
  box-shadow: 0px 5px 10px 2px rgba(174, 232, 191, 0.2);
}
.header-container {
  position: relative;
  text-align: center;
  padding: 10px;
  margin: 8px auto;
}
.corner__title {
  font-weight: normal;
  font-size: 20px;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1f271b;
  margin-bottom: 3px;
  position: relative;
}
.corner-name {
  font-family: "Futura New";
  font-weight: normal;
  font-size: 20px;
  line-height: 1;
  color: #1f271b;
}
.icon-login {
  position: absolute;
  right: 5px;
  margin-top: 5px;
}
.orders__tabs {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  width: 100%;
  padding: 8px 0;
  cursor: pointer;
}
.buttons__wrapper {
  display: flex;
  justify-content: flex-end;
}
.tabs-button {
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 6px 6px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-left: 4px;
  cursor: pointer;
}
.button-container {
  display: flex;
  justify-content: flex-start;
  max-width: 207px;
  margin-right: 16px;
}
.button--active {
  background-color: #f5f9fe;
}
.tabs-button__text {
  font-size: 12px;
  line-height: 1.4;
}
.button--active .tabs-button__text {
  font-weight: bold;
}
.tabs-button__text--inactive {
  font-size: 12px;
  line-height: 1.4;
  color: #97a091;
}
.tabs-button__counter {
  margin-left: 4px;
  font-size: 12px;
  line-height: 1.4;
  text-align: center;
  color: #ffffff;
  background-color: #039be5;
  padding: 0px 6px;
  border-radius: 24px;
}
.orders {
  overflow: visible;
}
.orders__list {
  background-color: #f5f9fe;
  padding-top: 120px;
  border-top: 1px solid #e5e5e5;
  z-index: 10;
  position: relative;
}
.orders__card {
  display: flex;
  justify-content: space-between;
  position: relative;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 12px;
}
.orders__card-left {
  display: flex;
  justify-content: start;
  align-items: center;
}
.orders__card-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}
.order__pack {
  display: block;
  position: absolute;
  bottom: 4px;
  right: 4px;
}
.card__icon {
  margin-right: 9px;
}
.card__info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.info__number {
  font-size: 18px;
  line-height: 1.4;
}
.info__contact {
  font-size: 10px;
  line-height: 1.4;
  color: #19647e;
  opacity: 0.34;
  margin-bottom: 5px;
}
.info__contact a {
  font-size: 10px;
  line-height: 1.4;
  color: #19647e;
  text-decoration: none;
}
.info__price {
  font-size: 14px;
  line-height: 1.4;
  color: #28afb0;
}
.order__properties {
  display: flex;
  justify-content: flex-end;
}
.order__comment {
  display: flex;
  justify-content: flex-end;
  align-items: start;
}
.comment-icon--hidden {
  display: none;
}
.order__status {
  margin-left: 4px;
  padding: 2px 8px;
  border-radius: 8px;
  font-size: 12px;
  line-height: 1.4;
  color: #ffffff;
}
.order__status--new {
  background-color: #039be5;
}
.order__status--timer {
  background-color: #60d6a9;
}
.order__status--processing {
  background-color: #ffa640;
}
.order__status--complete,
.order__status--finished {
  background-color: #00bc29;
}
.order__status--canceled {
  background-color: #afafaf;
}
.order__pack {
  font-size: 14px;
  line-height: 1.4;
}
.order__item {
  padding: 8px;
  border-radius: 8px;
  background: #fff;
  border: 1px solid #e5e5e5;
  margin-bottom: 80px;
}
.order {
  margin: 10px auto;
  max-width: 376px;
  padding-bottom: 4px;
  padding: 10px;
}
.order__row {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
}
.order__row + .order__row {
  margin-top: 4px;
}
.order__col-name {
  font-size: 16px;
  color: #0f4152;
  font-weight: 400;
}
.order__col-value {
  font-size: 16px;
  color: #19647e;
  text-align: right;
}
.order__client {
  color: #19647e;
}
.order__client a {
  color: #19647e;
  margin-left: 5px;
  text-decoration: none;
}
.order__button-wrap {
  display: flex;
  flex-direction: row;
  background: #f5f9fe;
  box-shadow: 0 -2px 20px rgba(49, 67, 82, 0.18);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 8px;
}
.order__button {
  display: flex;
  flex-direction: row;
  background: rgba(31, 29, 27, 0.1);
  box-shadow: -1px 3px 10px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  border: 0;
  height: 56px;
  line-height: 56px;
  margin: 4px;
  max-width: 359px;
  font-weight: 600;
  width: 100%;
  font-size: 16px;
  color: rgba(25, 100, 126, 0.4);
  display: flex;
  justify-content: center;
  flex-basis: 47%;
  flex-grow: 1;
  flex-shrink: 1;
}
.order__button svg {
  display: none;
}
.order__button_accept {
  border-radius: 8px;
  background: transparent;
  align-items: center;
  transition: 0.5s;
}
.order__button_accept svg {
  display: block;
  fill: #000;
  margin-right: 12px;
}
.order__button_deny {
  border: 2px solid #c85f35;
  border-radius: 8px;
  background: transparent;
  color: #c85f35;
  align-items: center;
}
.order__button_deny svg {
  display: block;
  fill: #fff;
  margin-right: 12px;
}
.order__title {
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #1f271b;
  margin-bottom: 10px;
  max-width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 4px;
  background-color: #fff;
}
.order__title svg {
  fill: #1f271b;
  stroke: none;
  stroke-width: 2px;
  margin-left: 8px;
  margin-top: 4px;
}
.order__table {
  max-width: 100%;
  margin: auto;
  margin-top: 50px;
}
.buttons_block {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}
.order__button_accept--active {
  flex-basis: 80%;
}
.order__button_deny--active {
  flex-basis: 80%;
}
.deny__form {
  position: absolute;
  bottom: 50%;
  left: 0;
  right: 0;
  transition: 5;
  background-color: #fff;
  box-shadow: 0 -2px 20px #314352;
  z-index: 1000;
  line-height: 1.4;
  border-radius: 15px;
  text-align: center;
  max-width: 280px;
  margin: 0 auto;
  color: #000;
  font-size: 16px;
  padding-top: 16px;
}
.deny_text {
  padding-bottom: 16px;
}
.deny_buttons {
  display: flex;
  overflow: hidden;
}
.btn_deny {
  border: none;
  border-top: 1px solid #afafaf;
  border-bottom-left-radius: 15px;
  background-color: #fff;
  flex-basis: 50%;
  padding: 10px 0;
}
.btn_accept {
  border: none;
  border-top: 1px solid #afafaf;
  border-left: 1px solid #afafaf;
  border-bottom-right-radius: 15px;
  background-color: #fff;
  flex-basis: 50%;
  padding: 10px 0;
}
.status {
  text-align: center;
  font-size: 20px;
  padding: 4px 10px;
  font-weight: 600;
  margin: 4px 10px;
}
.order__status--waiting {
  color: #039be5;
}
.order__status--confirmed {
  color: #60d6a9;
}
.order__status--refused {
  color: #28afb0;
}
.order__status--cancelled {
  color: #28afb0;
}
.order__status--issued_courier {
  color: #00bc29;
}
.order__status--expects {
  color: #ffa640;
}
.order__status--cooking {
  color: #c85f35;
}
.order__status--ready {
  color: #00bc29;
}
.order__status--waiting_on_counter {
  color: #00bc29;
}
.order__status--issued_courier {
  color: #60d6a9;
}
.order__status--received {
  color: #03751c;
}
.btn--waiting {
  background-color: #00bc29;
  color: #fff;
}
.btn--confirmed {
  background-color: #60d6a9;
  color: #fff;
}
.btn--refused {
  background-color: #28afb0;
  color: #fff;
}
.btn--expects {
  background-color: #ffa640;
  color: #fff;
}
.btn--cooking {
  background-color: #26d320;
  color: #fff;
}
.btn--ready {
  background-color: #00bc29;
  color: #fff;
}
.btn--waiting_on_counter {
  background-color: #00bc29;
  color: #fff;
}
.btn--issued_courier {
  background-color: #1f271b;
  color: #fff;
}
.btn--received {
  background-color: #03751c;
  color: #fff;
}
.order__date {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  color: #60d6a9;
}
.order__position {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.order__weight {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
}
.complete {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 19;
  width: 100%;
  overflow: scroll;
  background: linear-gradient(270.17deg, #006A97 -4.85%, #D4FF9D 118.36%), linear-gradient(180.09deg, #006A97 9.65%, #D4FF9D 125.55%), #121515;
  border-top: 1px solid #e5e5e5;
  padding: 8px 0;
}
.complete-orders {
  display: flex;
  flex-direction: row;
}
.orders__card--complete {
  min-width: 260px;
  flex-shrink: 0;
  margin-right: 8px;
}
.success__splash {
  position: absolute;
  bottom: 90px;
  left: 0;
  right: 0;
  transition: 5;
  background-color: #00bc29;
  z-index: 1000;
  line-height: 30px;
  border-radius: 15px;
  text-align: center;
  max-width: 70%;
  margin: 0 auto;
  color: #fff;
  font-size: 16px;
}
.splash {
  align-items: bottom;
}
.switch {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 20px;
  margin-left: 20px;
}
/* Hide default HTML checkbox */
.switch input {
  display: none;
}
/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px solid #19647e;
  background-color: transparent;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 2px;
  bottom: 2px;
  background-color: #ee534b;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .slider {
  background-color: #28afb0;
}
input:focus + .slider::before {
  background-color: #f5f9fe;
}
input:checked + .slider::before {
  background-color: #f5f9fe;
}
input:focus + .slider {
  box-shadow: 0 0 1px #28afb0;
}
input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
.categories {
  background-color: #f5f9fe;
  padding-top: 114px;
}
.categories__stop {
  display: flex;
  justify-content: space-between;
  padding: 16px 8px 16px 12px;
}
.stop__text {
  font-size: 16px;
  line-height: 1.4;
}
.categories__list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px 0;
  width: 100%;
}
.categories__card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 0px 8px;
  border-radius: 8px;
  margin-bottom: 8px;
  border: 1px solid #e5e5e5;
}
.categories__content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.categories__burger {
  align-items: center;
  object-fit: cover;
  width: 24px;
  height: 24px;
}
.categories__image {
  height: 52px;
  width: 52px;
  object-fit: cover;
  margin-right: 8px;
  margin-left: 4px;
}
.categories__image img {
  height: 40px;
  width: 40px;
  margin: 6px 6px 6px 6px;
}
.categories__name {
  font-size: 20px;
  line-height: 1.4;
}
.categories__name--inactive {
  opacity: 0.2;
}
.categories__nav {
  width: 8px;
  height: 14px;
  object-fit: cover;
}
.outlet__nan {
  color: red;
}
.outlets__title {
  color: white;
  margin: auto;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
}
.categorie__back {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.categorie__title {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
}
.categorie__stop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.categorie__control {
  margin-top: 20px;
}
.categorie__name {
  font-size: 20px;
  line-height: 1.4;
}
.categorie__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.categorie__card {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 14px 8px;
  border-radius: 8px;
  margin-left: 8px;
  margin-bottom: 8px;
  border: 1px solid #e5e5e5;
  min-width: 90%;
}
.non-ninja-card {
  background-color: #f5fefef0;
}
.categorie__items {
  margin-bottom: 20px;
}
.card__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card__name {
  max-width: 144px;
  font-size: 14px;
  line-height: 1.1;
}
.card__information {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.card__options {
  border-top: 1px dashed #e5e5e5;
  margin-top: 8px;
}
.options__title {
  border: 0;
  font-size: 12px;
  line-height: 1.1;
  color: #19647e;
  background-color: transparent;
  cursor: pointer;
}
.card__option-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px dashed #e5e5e5;
  margin-top: 8px;
  padding: 8px 0;
}
.option__name {
  font-size: 12px;
  color: #19647e;
  max-width: 70%;
}
.card__option-item--inactive > .option__name {
  opacity: 0.4;
}
.card__option-item--inactive > .card__information > .card__price {
  opacity: 0.4;
}
.categorie__card--inactive > .card__container > .card__name,
.categorie__card--inactive > .card__container > .card__information > .card__price {
  opacity: 0.4;
}
.card__weight {
  font-size: 10px;
  color: #19647e;
}
.card__product {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.card__product img {
  border-radius: 50%;
  margin: auto;
}
.card__img {
  border-radius: 50%;
  margin: auto;
}
.card__name {
  margin-left: 4px;
}
.page__container {
  flex: 1 0 auto;
}
.page__content {
  height: 100%;
}
.main__container {
  box-sizing: border-box;
  min-width: 320px;
  padding: 0 16px;
  margin-top: 111px;
}
@media (min-width: 768px) {
  .main__container {
    width: 720px;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
  }
}
@media (min-width: 1200px) {
  .main__container {
    width: 1144px;
  }
}
@media (min-width: 1920px) {
  .main__container {
    width: 1530px;
  }
}
.main__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.main__logo {
  width: 125px;
  height: 108px;
  margin-bottom: 13px;
}
.main__logo img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
.input {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 193px;
  margin-bottom: 16px;
}
.input {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 193px;
  margin-bottom: 16px;
}
.input__input {
  font-size: 16px;
  line-height: 36px;
  height: 36px;
  padding: 0 8px;
  resize: none;
  outline: none;
  color: #000;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #f5f9fe;
  border: 1px solid #28afb0;
  box-sizing: border-box;
  border-radius: 8px;
}
.input__input::-moz-placeholder {
  color: #1f271b;
  opacity: 0.2;
}
.input__input:-ms-input-placeholder {
  color: #1f271b;
  opacity: 0.2;
}
.input__input::placeholder {
  color: #1f271b;
  opacity: 0.2;
}
.input__input::-ms-clear {
  display: none;
}
.input__input_error {
  border-color: #f00;
}
.input_error-text {
  display: none;
}
.input_error .input__input {
  border-color: #f00;
}
.input_error .input__label {
  display: flex;
}
.main__button {
  background: #2baa99;
  box-shadow: -1px 3px 10px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  width: 193px;
  height: 41px;
  font-size: 16px;
  line-height: 41px;
  color: #fff;
  border: 0;
  font-weight: 500;
}
.main__logo-text {
  width: 134px;
  height: 25px;
  margin-bottom: 6px;
  text-align: center;
}
.popup-page__content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2000;
  text-align: center;
}
.popup-page__content a {
  color: #19647e;
  margin-left: 5px;
  text-decoration: none;
}
.profile__data {
  font-size: 16px;
  color: #19647e;
  margin-left: 5px;
  text-decoration: none;
}
.market__label {
  color: #000;
  margin-left: 5px;
  text-decoration: none;
}
.profile-page__body {
  position: relative;
  background: rgba(246, 250, 251, 0.815);
  border-radius: 16px;
  padding: 44px 4px;
  width: 240px;
  box-sizing: border-box;
  align-items: center;
  text-align: center;
}
.profile__img {
  position: absolute;
  top: -65px;
  left: 70px;
}
.profile__img img {
  box-shadow: -1px 3px 10px rgba(0, 0, 0, 0.06);
  border-radius: 50px;
}
.corner__name {
  font-size: 24px;
  line-height: 34px;
  color: #000000;
  margin-bottom: 8px;
  width: 90%;
  margin: 4px auto;
}
.popup-page__logout {
  cursor: pointer;
  background-color: transparent;
  border: none;
  margin: 10px auto;
}
.overlay {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  transition: 0.3s;
}
.overlay_active {
  opacity: 1;
  visibility: visible;
}
.overlay-success {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: linear-gradient(270.17deg, #006a97 -4.85%, #d4ff9d 118.36%), linear-gradient(180.09deg, #006a97 9.65%, #d4ff9d 125.55%);
  z-index: 1000;
  transition: 0.3s;
}
.new_order {
  display: flex;
  flex-direction: column;
  background-color: rgba(10, 184, 155, 0.3);
  z-index: 1001;
  text-align: center;
  position: absolute;
  top: 20%;
  right: 30px;
  left: 30px;
  border-radius: 12px;
  box-shadow: -1px 3px 10px rgba(0, 0, 0, 0.06);
}
.new_order_num {
  font-size: 80px;
  font-weight: 600;
  color: #fff;
}
.new_order_text {
  padding: 10 auto;
  font-size: 16px;
  font-weight: 300;
  color: #fff;
  margin-top: 14px;
}
.ocean {
  height: 5%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #015871;
}
.wave {
  background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/85486/wave.svg) repeat-x;
  position: absolute;
  top: -198px;
  width: 6400px;
  height: 198px;
  animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
}
.wave:nth-of-type(2) {
  top: -175px;
  animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite, swell 7s ease -1.25s infinite;
  opacity: 1;
}
@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}
@keyframes swell {
  0%, 100% {
    transform: translate3d(0, -25px, 0);
  }
  50% {
    transform: translate3d(0, 5px, 0);
  }
}
.loader {
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}
.fond {
  margin: auto;
}
.container_general {
  -webkit-animation: animball_two 1s infinite;
  -moz-animation: animball_two 1s infinite;
  -ms-animation: animball_two 1s infinite;
  animation: animball_two 1s infinite;
  width: 44px;
  height: 44px;
}
.container_mixt {
  width: 44px;
  height: 44px;
  position: absolute;
}
.ballcolor {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.ball_1, .ball_2, .ball_3, .ball_4 {
  position: absolute;
  -webkit-animation: animball_one 1s infinite ease;
  -moz-animation: animball_one 1s infinite ease;
  -ms-animation: animball_one 1s infinite ease;
  animation: animball_one 1s infinite ease;
}
.ball_1 {
  background-color: #cb2025;
  top: 0;
  left: 0;
}
.ball_2 {
  background-color: #f8b334;
  top: 0;
  left: 24px;
}
.ball_3 {
  background-color: #00a096;
  top: 24px;
  left: 0;
}
.ball_4 {
  background-color: #97bf0d;
  top: 24px;
  left: 24px;
}
@-webkit-keyframes animball_one {
  0% {
    position: absolute;
  }
  50% {
    top: 12px;
    left: 12px;
    position: absolute;
    opacity: 0.5;
  }
  100% {
    position: absolute;
  }
}
@-moz-keyframes animball_one {
  0% {
    position: absolute;
  }
  50% {
    top: 12px;
    left: 12px;
    position: absolute;
    opacity: 0.5;
  }
  100% {
    position: absolute;
  }
}
@-ms-keyframes animball_one {
  0% {
    position: absolute;
  }
  50% {
    top: 12px;
    left: 12px;
    position: absolute;
    opacity: 0.5;
  }
  100% {
    position: absolute;
  }
}
@keyframes animball_one {
  0% {
    position: absolute;
  }
  50% {
    top: 12px;
    left: 12px;
    position: absolute;
    opacity: 0.5;
  }
  100% {
    position: absolute;
  }
}
@-webkit-keyframes animball_two {
  0% {
    -webkit-transform: rotate(0deg) scale(1);
  }
  50% {
    -webkit-transform: rotate(360deg) scale(1.3);
  }
  100% {
    -webkit-transform: rotate(720deg) scale(1);
  }
}
@-moz-keyframes animball_two {
  0% {
    -moz-transform: rotate(0deg) scale(1);
  }
  50% {
    -moz-transform: rotate(360deg) scale(1.3);
  }
  100% {
    -moz-transform: rotate(720deg) scale(1);
  }
}
@-ms-keyframes animball_two {
  0% {
    -ms-transform: rotate(0deg) scale(1);
  }
  50% {
    -ms-transform: rotate(360deg) scale(1.3);
  }
  100% {
    -ms-transform: rotate(720deg) scale(1);
  }
}
@keyframes animball_two {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(360deg) scale(1.3);
  }
  100% {
    transform: rotate(720deg) scale(1);
  }
}
/*# sourceMappingURL=index.3920ce09.css.map */
