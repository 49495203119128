.switch {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 20px;
  margin-left: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  display: none;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px solid #19647e;
  background-color: transparent;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 12px;
  width: 12px;
  left: 2px;
  bottom: 2px;
  background-color: #ee534b;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #28afb0;
}

input:focus + .slider::before {
  background-color: #f5f9fe;
}
input:checked + .slider::before {
  background-color: #f5f9fe;
}

input:focus + .slider {
  box-shadow: 0 0 1px #28afb0;
}

input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.categories {
  background-color: #f5f9fe;
  padding-top: 114px;
}
.categories__stop {
  display: flex;
  justify-content: space-between;
  padding: 16px 8px 16px 12px;
}
.stop__text {
  font-size: 16px;
  line-height: 1.4;
}
.categories__list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px 0;
  width: 100%;
}
.categories__card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 0px 8px;
  border-radius: 8px;
  margin-bottom: 8px;
  border: 1px solid #e5e5e5;
}
.categories__content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.categories__burger {
  align-items: center;
  object-fit: cover;
  width: 24px;
  height: 24px;
}
.categories__image {
  height: 52px;
  width: 52px;
  object-fit: cover;
  margin-right: 8px;
  margin-left: 4px;
}
.categories__image img {
  height: 40px;
  width: 40px;
  margin: 6px 6px 6px 6px;
}
.categories__name {
  font-size: 20px;
  line-height: 1.4;
}
.categories__name--inactive {
  opacity: 0.2;
}
.categories__nav {
  width: 8px;
  height: 14px;
  object-fit: cover;
}

.outlet__nan {
  color: red;
}

.outlets__title {
  color: white;
  margin: auto;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
}

.categorie__back {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.categorie__title {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
}
